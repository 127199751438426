body {
  /* background-color: rgb(0, 0, 0); */
  /* height: 200vh; */
  background: rgb(43, 44, 52);
  /* background-image: url('../bg.png'); */

  color: rgb(0, 0, 0);
}
#main {
  font-family: Roboto;
  text-align: center;
}

#bg {
  z-index: -1;
  width: 100%;
  text-align: center;
}

.color-picker {
  background: rgba(40, 40, 40, 0.8);
  color: #1e0cfc;
  color: rgb(0, 0, 255);
}
/* h1 {color:rgb(255, 255, 255))} */
